export const categories = [
  {
    text: "Commercial",
    value: "commercial",
    icon: "mdi-sale",
  },
  {
    text: "Logistique",
    value: "logistique",
  },
  {
    text: "Comptabilité",
    value: "comptabilite",
  },
  {
    text: "Production",
    value: "production",
  },
  {
    text: "Administration",
    value: "parametrage",
  },
];
export const pages = [
  {
    icon: "mdi-store",
    text: "Clients",
    route: "/clients",
    type: "page",
    parent: "commercial",
    permission: ["commercial"],
  },
  {
    icon: "mdi-cart",
    text: "Commande",
    route: "/commande",
    type: "page",
    parent: "commercial",
    permission: ["commercial"],
  },
  {
    icon: "mdi-chart-bar",
    text: "Statistiques",
    route: "/stats",
    type: "page",
    parent: "commercial",
    permission: ["commercial"],
  },
  {
    icon: "mdi-package-variant-closed",
    text: "Produits",
    route: "/produits",
    type: "page",
    parent: "commercial",
    permission: ["commercial"],
  },
  {
    icon: "mdi-clipboard-edit-outline",
    text: "Bon Preparation",
    route: "/bonPreparation",
    type: "page",
    divider: true,
    parent: "logistique",
    permission: ["commercial", "logisticien"],
  },
  {
    icon: "mdi-arrow-decision-outline",
    text: "Flux de produit",
    route: "/fluxProduct",
    type: "page",
    divider: true,
    parent: "logistique",
    permission: ["commercial", "logisticien"],
  },
  {
    icon: "mdi-archive",
    text: "Entrée logistique",
    route: "/storing",
    type: "page",
    parent: "logistique",
    permission: ["logisticien"],
  },
  {
    icon: "mdi-database",
    text: "Stock",
    route: "/stock",
    type: "page",
    parent: "logistique",
    permission: ["commercial", "logisticien"],
  },
  {
    icon: "mdi-notebook",
    text: "Journaux comptables",
    route: "/journauxComptables",
    type: "page",
    parent: "comptabilite",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-receipt-text-check",
    text: "Facture",
    route: "/facture",
    type: "page",
    parent: "comptabilite",
    permission: ["administrateur", "commercial"],
  },
  {
    icon: "mdi-cash-multiple",
    text: "Transaction",
    route: "/transaction",
    type: "page",
    parent: "comptabilite",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-printer",
    text: "Imprimantes",
    route: "/printers",
    type: "page",
    parent: "parametrage",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-account",
    text: "Utilisateurs",
    route: "/utilisateurs",
    type: "page",
    parent: "parametrage",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-wrench",
    text: "Configuration du site",
    route: "/variables",
    type: "page",
    parent: "parametrage",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-image",
    text: "Médias",
    route: "/media",
    type: "page",
    parent: "parametrage",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-printer",
    text: "Labels",
    route: "/label",
    type: "page",
    parent: "logistique",
    permission: ["administrateur", "logisticien"],
  },
  {
    icon: "mdi-factory",
    text: "Fabrication",
    route: "/production",
    type: "page",
    parent: "production",
    permission: ["logisticien"],
  },
  {
    icon: "mdi-atom",
    text: "Matières premières",
    route: "/rawMaterial",
    type: "page",
    parent: "production",
    permission: ["logisticien"],
  },
  {
    icon: "mdi-qrcode",
    text: "QR Code",
    route: "/qrCode",
    type: "page",
    parent: "parametrage",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-web",
    text: "3Dmap.fr",
    route: "https://3dmap.fr/",
    type: "application",
  },
  {
    icon: "mdi-map",
    text: "Map Builder",
    route: "https://lab2.3dmap.fr/geo/",
    type: "application",
    permission: ["administrateur"],
  },
  {
    icon: "mdi-cloud",
    text: "Drive",
    route: "https://drive.google.com/drive/team-drives",
    type: "application",
    permission: ["administrateur"],
  },
];

export const getByTypeWithPermission = (type: string, permission: Array<string>) =>
  pages.filter((page) => page.type === type && (page.permission ? [...page.permission, "administrateur"].some((value) => permission.includes(value)) : true));

export const getPagesByCategoryWithPermission = (category: string, permission: Array<string>) =>
  getByTypeWithPermission("page", permission).filter((page) => page.parent === category);

export const getApplicationWithPermission = (permission: Array<string>) => getByTypeWithPermission("application", permission);
