<template>
  <v-navigation-drawer v-if="connected" width="20rem" v-model="drawerProxy" fixed clipped class="mt-12 text-left">
    <v-list dense nav>
      <v-list-group v-for="category in categoryPages" v-model="categoryToggle[category.value]" no-action sub-group>
        <template #activator>
          <v-list-item-title>{{ category.text }}</v-list-item-title>
        </template>
        <v-list-item v-for="page in category.children" :key="page.route" :to="page.route" link>
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ page.text }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { categories, getPagesByCategoryWithPermission } from "@/config/navigation";
import { useStorage } from "@vueuse/core";
import { computed, reactive, toRefs } from "vue";

const props = defineProps({
  drawer: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits<{ (e: "update:drawer", value: boolean) }>();

const { connected, privilege } = toRefs(reactive(controller));
const categoryToggle = useStorage("navigation-category-toggle", {});

const categoryPages = computed(() =>
  categories
    .map((category) => ({ ...category, children: getPagesByCategoryWithPermission(category.value, privilege.value) }))
    .filter((item) => item.children.length !== 0)
);

const drawerProxy = computed({
  get() {
    return props.drawer;
  },
  set(value: boolean) {
    emits("update:drawer", value);
  },
});
</script>
