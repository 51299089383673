import { dateFormatter, datetimeFormatter, moneyFormatter, percentFormatter, weightFormatter } from "@/lib/formatter"

export const formatterMixin = {
    methods: {
        formatPrice,
        formatDate,
        formatWeight,
        formatDatetime,
        formatPercent,
        formatBoolean
    }
}

export function formatPrice(price) {
    if (typeof price === "string") {
        price = Number(price.replace(",", "."));
    }
    return moneyFormatter.format(Number(price))
}
export function formatDate(date) {
    if (!date) return ""
    return dateFormatter.format(new Date(date))
}
export function formatWeight(weight = 0) {
    return weightFormatter.format(weight)
}
export function formatDatetime(date) {
    if (!date) return ""
    return datetimeFormatter.format(new Date(date))
}
export function formatPercent(percent) {
    return percentFormatter.format(percent / 100);
}
export function formatBoolean(bool) {
    return bool ? "Oui" : "Non"
}