import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "accueil",
    component: () => import("../apps/accueil/main.vue"),
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../apps/pages/commercial/client/main.vue"),
  },
  {
    path: "/clients/new",
    name: "clientNew",
    component: () => import("../apps/pages/commercial/client/id/edit.vue"),
  },
  {
    path: "/clients/:id",
    name: "clientsInfo",
    component: () => import("../apps/pages/commercial/client/id/view.vue"),
  },
  {
    path: "/clients/edit/:id",
    name: "editClient",
    component: () => import("../apps/pages/commercial/client/id/edit.vue"),
  },
  {
    path: "/media",
    name: "media",
    component: () => import("../apps/pages/parametre/media/main.vue"),
  },
  {
    path: "/media/:id",
    name: "editMedia",
    component: () => import("../apps/pages/parametre/media/edit.vue"),
  },
  {
    path: "/bonPreparation",
    name: "bonPreparation",
    component: () => import("../apps/pages/logistique/bonPreparation/main.vue"),
  },
  {
    path: "/bonPreparation/new",
    name: "newBonPreparation",
    component: () => import("../apps/pages/logistique/bonPreparation/newAndEdit.vue"),
  },
  {
    path: "/bonPreparation/:id",
    name: "ficheBonPreparation",
    component: () => import("../apps/pages/logistique/bonPreparation/fiche.vue"),
  },
  {
    path: "/bonPreparation/edit/:id",
    name: "editBonPreparation",
    component: () => import("../apps/pages/logistique/bonPreparation/newAndEdit.vue"),
  },
  {
    path: "/produits",
    name: "product",
    component: () => import("../apps/pages/commercial/produits/main.vue"),
  },
  {
    path: "/produits/new",
    name: "product-create",
    component: () => import("../apps/pages/commercial/produits/edit.vue"),
  },
  {
    path: "/produits/edit/:id",
    name: "product-edit",
    component: () => import("../apps/pages/commercial/produits/edit.vue"),
  },
  {
    path: "/produits/:id",
    name: "product-id",
    component: () => import("../apps/pages/commercial/produits/view.vue"),
  },
  {
    path: "/enseigne",
    name: "enseigne",
    component: () => import("../apps/enseigne/main.vue"),
  },
  {
    path: "/activite",
    name: "activite",
    component: () => import("../apps/activite/main.vue"),
  },
  {
    path: "/new_user",
    name: "new_user",
    component: () => import("../apps/pages/parametre/Utilisateurs/new.vue"),
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("../apps/pages/commercial/statistique/main.vue"),
  },
  {
    path: "/journauxComptables",
    name: "journauxComptables",
    component: () => import("../apps/pages/comptabilite/journauxComptables/main.vue"),
  },
  // {
  //   path: '/clients/latlng/map',
  //   name: 'clientmap',
  //   component: () => import('../apps/clients/clientmap.vue'),
  //   meta: { transitionName: 'zoom' }
  // },
  {
    path: "/commande",
    name: "commande",
    component: () => import("../apps/pages/commercial/commande/main.vue"),
  },
  {
    path: "/commande/new",
    name: "newCommande",
    component: () => import("../apps/pages/commercial/commande/id/edit.vue"),
  },
  {
    path: "/commande/edit/:id",
    name: "editCommande",
    component: () => import("../apps/pages/commercial/commande/id/edit.vue"),
  },
  {
    path: "/commande/:id",
    name: "ficheCommande",
    component: () => import("../apps/pages/commercial/commande/id/view.vue"),
  },
  {
    path: "/FamilleProduit",
    name: "FamilleProduit",
    component: () => import("../apps/FamilleProduit/main.vue"),
  },
  {
    path: "/tarifications",
    name: "tarification",
    component: () => import("../apps/tarification/main.vue"),
  },
  {
    path: "/variables",
    name: "config",
    component: () => import("../apps/pages/parametre/configuration/main.vue"),
  },
  {
    path: "/fluxProduct",
    name: "fluxProduct",
    component: () => import("../apps/pages/logistique/fluxProduct/main.vue"),
  },
  {
    path: "/fluxProduct/new",
    name: "newFluxProduct",
    component: () => import("../apps/pages/logistique/fluxProduct/modification/newAndEditFluxProduct.vue"),
  },
  {
    path: "/fluxProduct/:id",
    name: "ficheFluxProduct",
    component: () => import("../apps/pages/logistique/fluxProduct/modification/ficheFluxProduct.vue"),
  },
  {
    path: "/fluxProduct/edit/:id",
    name: "editFluxProduct",
    component: () => import("../apps/pages/logistique/fluxProduct/modification/newAndEditFluxProduct.vue"),
  },
  {
    path: "/utilisateurs",
    name: "utilisateurs",
    component: () => import("../apps/pages/parametre/Utilisateurs/main.vue"),
  },
  {
    path: "/utilisateurs/view/:id",
    name: "ficheUtilisateurs",
    component: () => import("../apps/pages/parametre/Utilisateurs/fiche.vue"),
  },
  {
    path: "/utilisateurs/edit/:id",
    name: "editUtilisateurs",
    component: () => import("../apps/pages/parametre/Utilisateurs/edit.vue"),
  },
  {
    path: "/facture",
    name: "facture",
    component: () => import("../apps/pages/comptabilite/facture/main.vue"),
  },
  {
    path: "/facture/:id",
    name: "ficheFacture",
    component: () => import("../apps/pages/comptabilite/facture/fiche.vue"),
  },
  {
    path: "/transaction/new",
    name: "newTransaction",
    component: () => import("../apps/pages/comptabilite/facture/newTransaction.vue"),
  },
  {
    path: "/transaction/newMultiples",
    name: "newTransactionsMultiples",
    component: () => import("../apps/pages/comptabilite/facture/newTransactionsMultiples.vue"),
  },
  {
    path: "/transaction",
    name: "transaction",
    component: () => import("../apps/pages/comptabilite/transaction/main.vue"),
  },
  {
    path: "/stock",
    name: "stock",
    component: () => import("../apps/pages/logistique/stock/main.vue"),
  },
  {
    path: "/storing",
    name: "storing",
    component: () => import("../apps/pages/logistique/storing/main.vue"),
  },
  {
    path: "/storing/create",
    name: "storing-create",
    component: () => import("../apps/pages/logistique/storing/create.vue"),
  },

  {
    path: "/printers",
    name: "printers",
    component: () => import("../apps/pages/parametre/printer/view.vue"),
  },
  {
    path: "/stock/inventory/create",
    name: "stock_inventory_create",
    component: () => import("../apps/pages/logistique/stock/inventory/create.vue")
  },
  {
    path: "/label",
    name: "label_printing",
    component: () => import("@/apps/pages/logistique/label/main.vue")
  },
  {
    path: "/production",
    name: "production",
    component: () => import("../apps/pages/logistique/production/main.vue"),
  },
  {
    path: "/production/create",
    name: "production-create",
    component: () => import("../apps/pages/logistique/production/create.vue"),
  },
  {
    path: "/production/machine",
    name: "production-machine",
    component: () => import("../apps/pages/logistique/production/machine/main.vue"),
  },
  {
    path: "/production/machine/create",
    name: "production-machine-create",
    component: () => import("../apps/pages/logistique/production/machine/create.vue"),
  },
  {
    path: "/production/:id",
    name: "production-id",
    component: () => import("../apps/pages/logistique/production/id.vue"),
  },
  {
    path: "/rawMaterial",
    name: "rawMaterial",
    component: () => import("../apps/pages/logistique/production/rawMaterial/main.vue"),
  },
  {
    path: "/rawMaterial/create",
    name: "rawMaterial-create",
    component: () => import("../apps/pages/logistique/production/rawMaterial/create.vue"),
  },
  {
    path: "/rawMaterial/:id",
    name: "rawMaterial-id",
    component: () => import("../apps/pages/logistique/production/rawMaterial/id.vue"),
  },
  {
    path: "/qrCode",
    name: "qrCode",
    component: () => import("../apps/pages/parametre/qrcode/main.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { ...savedPosition, behavior: "smooth" };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
