<template>
  <v-app>
    <DialogManager />
    <v-container v-if="isReloging && !connected" class="flex justify-center align-center d-flex fill-height">
      <v-progress-circular indeterminate color="primary" dark size="64"></v-progress-circular>
    </v-container>
    <template v-else>
      <login v-if="!connected && !isReloging" />
      <template v-if="connected && !isReloging">
        <Navbar :drawer.sync="drawer" />
        <Navdrawer :drawer.sync="drawer" />
        <transition :name="transitionName" :mode="transitionMode" :enter-active-class="transitionEnterActiveClass">
          <slot v-if="connected" />
        </transition>
      </template>
      <v-snackbar :color="notification.type" v-model="notification.show" :timeout="notification.delay">
        {{ notification.text }}
      </v-snackbar>
      <v-dialog class="absolute" v-if="showError" v-model="showError">
        <v-card>
          <VueJsonPretty :data="error" />
        </v-card>
      </v-dialog>
    </template>
  </v-app>
</template>
<script setup lang="ts">
import DialogManager from "@/apps/components/dialog/DialogManager.vue";
import login from "@/apps/login/main.vue";
import controller from "@/init";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import Navbar from "../components/navigation/Navbar.vue";
import Navdrawer from "../components/navigation/Navdrawer.vue";
const DEFAULT_TRANSITION = "slide";
const DEFAULT_TRANSITION_MODE = "out-in";

import { reactive, toRefs } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router/composables";

const router = useRouter();

const { showError, error, notification, connected, isReloging, transitionName, transitionMode, transitionEnterActiveClass, pages } = toRefs(
  reactive(controller)
);

const drawer = ref(false);

router.beforeEach((to, from, next) => {
  let lTransitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION;

  if (lTransitionName === "slide") {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    lTransitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
  }

  transitionMode.value = DEFAULT_TRANSITION_MODE;
  transitionEnterActiveClass.value = `${lTransitionName}-enter-active`;

  transitionName.value = lTransitionName;

  next();
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
