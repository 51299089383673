import axios from "axios";
import config from "../config";
import { initSocket } from "./socket";

export default class {
  constructor(uri) {
    this.uri = uri;
    this.connected = false;
    this.isReloging = false;
    this.login = "";
    this.prevHeight = 0;
    this.transitionName = "slide";
    this.transitionMode = "out-in";
    this.transitionEnterActiveClass = "";
    this.nom = "";
    this.userTel = "";
    this.userID = ""
    this.privilege = [];
    this.app = "/";
    this.sessionID = "";

    this.showError = false;
    this.error = null;

    this.notification = {
      text: "",
      type: "",
      show: false,
      delay: 3000
    }; // success, info, warning, error

    this.drawer = false;
    this.fullscreen = false;
    this.pages = config.pages;

    let cookie = "";
    document.cookie.split(";").forEach((v) => {
      const [key, lcookie] = v.split("=").map((x) => x.trim());
      if (key === "Session-3dmap") {
        cookie = lcookie;
      }
    });

    if (cookie !== "") {
      this.isReloging = true;
      this.sessionID = cookie;
      this.api([
        "getUser",
        {
          filter: {
            sessionID: cookie,
          },
        },
      ])
        .catch(() => {
          this.isReloging = false;
          this.sessionID = null;
        })
        .then((res) => {
          if (res.data.body && res.data.body.total > 0) {
            const user = res.data.body.value[0];
            this.userID = user.ID;
            this.userTel = user.tel;
            this.login = user.login;
            this.privilege = user.privilege;
            this.nom = user.nom;

            this.connected = true;

            initSocket(this.uri, user.ID, this.sessionID).then(() => {
              this.isReloging = false;
            })
          } else {
            this.isReloging = false;
          }
        });
    }
  }

  /**
   *
   * @param {[string, any]} cmd
   * @param {*} onUploadProgress
   * @returns {Promise<any>} Response
   */
  async api(cmd, onUploadProgress = () => { }) {
    return axios
      .create({
        method: "post",
        baseURL: this.uri,
        withCredentials: true,
        headers: {
          "Session-3dmap": this.sessionID,
          "Version-Front": __APP_VERSION__,
          "Content-Type": "application/json",
        },
      })({ data: cmd, onUploadProgress })
      .then((res) => {
        if (res.data && res.data?.code == "error" && (res.data?.info || "").includes("UNAUTHORIZED")) {
          this.setNotification("Veuillez vous reconnecter", "error", 10000);
          this.sessionID = "";
          this.connected = false;
        }
        return res;
      });
  }

  setNotification(text, type, delay = 3000) {
    this.notification.text = text;
    this.notification.type = type;
    this.notification.show = true;
    this.notification.delay = delay;
  }

  setError(error) {
    this.error = error;
    this.showError = error;
  }

  connect(login, password) {
    return new Promise((resolve) => {
      return this.api(["auth", { login, password }]).then((j) => {
        if (j.data.body) {
          this.sessionID = j.data.body.sessionID;
          this.connected = true;
          this.userID = j.data.body.ID;
          this.userTel = j.data.body.tel;
          this.login = j.data.body.login;
          this.privilege = j.data.body.privilege;
          this.nom = j.data.body.nom;

          initSocket(this.uri, this.userID, this.sessionID);

          document.cookie = `Session-3dmap=${this.sessionID};Secure;max-age=${3600 * 6};`;
        } else {
          this.setNotification(j.data.info, "error");
        }
        resolve();
      });
    });
  }

  disconnect() {
    this.connected = false;
    this.login = "";
    this.nom = "";
    this.privilege = "";
    document.cookie = "Session-3dmap=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    return new Promise((resolve) => {
      return this.api(["quit"]).then((j) => {
        if (j.data.body) {
          this.sessionID = "";
        } else {
          this.setNotification(j.data.info, "error");
        }
        resolve();
      });
    });
  }
}
