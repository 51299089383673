import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from "vuetify/lib/util/colors"
import '@mdi/font/css/materialdesignicons.css'
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr'
  },
  icons: {
    iconfont:"mdi"
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#6666E6',
        secondary: colors.orange.lighten4,
        accent: colors.orange.darken,
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#6666E6',
        secondary: colors.orange.lighten4,
        accent: colors.orange.darken,
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
})