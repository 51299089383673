export default {
  env: {
    dev: {
      admin: 'https://devadmin.3dmap.fr/',
      site: 'https://devwww.3dmap.fr/',
      apiAdmin: __API_URL__ ?? 'https://devadmin.3dmap.fr/api'
    },
    prod: {
      admin: 'https://admin.3dmap.fr',
      site: 'https://3dmap.fr/',
      apiAdmin: 'https://admin.3dmap.fr/api'
    }
  },
}
