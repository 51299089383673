<template>
  <v-app-bar fixed dense color="primary">
    <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
    <v-btn text to="/">Back Office 3Dmap</v-btn>
    <span style="margin-left: 10px">v.{{ appVersion }}</span>
    <div class="fill-height pa-1 ml-4">
      <div class="white fill-height pa-2" style="border-radius: 2rem">
        <img src="/3DMAPlogo.png" class="fill-height" />
      </div>
    </div>
    <v-spacer></v-spacer>
    <span v-if="isDev" class="red--text font-weight-bold white pa-2">ATTENTION VERSION DE DEV</span>
    <v-spacer></v-spacer>
    <v-menu bottom left offsetY>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </template>
      <v-list dense>
          <v-list-item v-for="page in applications" :key="page.text" :href="page.route" target="_blank" link>
            <v-icon>{{ page.icon }}</v-icon>
            <v-list-item-content class="ml-1">{{ page.text }}</v-list-item-content>
          </v-list-item>
      </v-list>
    </v-menu>
    <v-menu bottom left offset-y open-on-click>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="nom + ' (' + privilege + ')'" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group>
          <v-list-item @click="disconnect">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="'Déconnexion'" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script setup lang="ts">
import { getApplicationWithPermission } from "@/config/navigation";
import { computed, reactive, toRefs } from "vue";

const isDev = computed(() => __ENV__ === "dev");

const props = defineProps({
  drawer: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits<{ (e: "update:drawer", value: boolean) }>();

const disconnect = () => controller.disconnect();
const { nom, privilege } = toRefs(reactive(controller));

const appVersion = `${__APP_VERSION__}/${__ENV__}`;

const applications = getApplicationWithPermission(controller.privilege);

const toggleDrawer = () => {
  emits("update:drawer", !props.drawer);
};
</script>
