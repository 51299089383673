export const permissionMixin = {
    methods: {
        isAdmin() {
            return controller.privilege.includes("administrateur");
        },
        hasPrivilege(...privileges) {
            privileges.push("administrateur");
            return privileges.some(item => {
                return controller.privilege.includes(item)
            })
        }
    }
}