import { io } from "socket.io-client";

/**@type {import("socket.io-client").Socket} */
export let socket;
/**
 * 
 * @param {string} uri 
 * @param {number} id 
 * @param {string} token 
 * @returns 
 */
export const initSocket = (uri, id, token) => {
  return new Promise((res) => {
    const url = new URL(uri)
    const s = io(url.origin, {
      reconnectionAttempts: 5,
      reconnectionDelay: 1000 * 60,
      query: { id: parseInt(id), token },
    });

    s.on("connect", () => {
      console.log("Socket connected");
      socket = s;
      res();
    });

    s.io.on("error", (err) => {
      console.error(err);
      res();
    });
    s.on("disconnect", () => {
      console.log("disconnect");
    });

    s.on("reconnect", () => {
      console.log("reconnect");
    });
  });
};
