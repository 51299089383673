<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card elevation-12>
            <v-toolbar flat dark color="primary" style="white-space: nowrap">
              <v-toolbar-title>Authentifications 3Dmap </v-toolbar-title><v-spacer></v-spacer> v.{{ appVersion }}
            </v-toolbar>
            <v-text-field
              label="Adresse e-mail"
              id="login"
              prepend-icon="mdi-account"
              class="ma-2"
              @keyup.enter="submitForm"
              v-model="login"
              autocomplete="email" />
            <v-text-field
              label="Mot de passe"
              id="password"
              prepend-icon="mdi-lock"
              type="password"
              class="mx-2"
              v-model="password"
              @keyup.enter="submitForm"
            autocomplete="current-password" />
            <v-card-actions>
              <v-spacer />
              <v-btn align="right" color="primary" @click="submitForm" :loading="isLoading">Se connecter</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import m from "./model";
export default {
  data: () => m,
  created() {
    this.isLoading = false;
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.$forceUpdate();
      this.connect();
      setTimeout(() => {
        this.isLoading = false;
        this.$forceUpdate();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.classb {
  display: inline-block;
  padding: 41px;
  color: black;
  font-size: smaller;
}
</style>
