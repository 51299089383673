import { QrCodeTargetType } from "./types/qrCode";

export const selectTernaryItems = [
  { text: "Indifférent", value: 0 },
  { text: "Oui", value: 1 },
  { text: "Non", value: 2 },
];

export const listOrigin = [
  { ID: -1, type: "production", text: "Production" },
  { ID: -1, type: "logistique", text: "Logistique" },
  { ID: -1, type: "fournisseur", text: "Fournisseur" },
  { ID: -1, type: "poubelle", text: "Poubelle" },
];

export const listCommandShippingStatut = [
  { id: "attentePreparation", text: "Attente préparation" },
  { id: "expedie", text: "Expédié" },
  { id: "attenteExpedition", text: "Attente expédition" },
  { id: "anomalie", text: "Anomalie" },
  { id: "livre", text: "Livré" },
];

export const listInvoiceStatut = [
  { id: "nonPaye", text: "Non payé" },
  { id: "paye", text: "Payé" },
  { id: "attente", text: "Attente" },
];

export const listCommandStatut = [
  { id: "valide", text: "Validé" },
  { id: "refus", text: "Refusé" },
  { id: "attente", text: "Attente" },
];

export const listFluxStatut = [
  { id: "livre", text: "Livré" },
  { id: "expedie", text: "Expedié" },
  { id: "nonExpedie", text: "Non Expedié" },
  { id: "available_in_relay", text: "Disponible en relais" },
  { id: "anomalie", text: "Anomalie" },
];

export const listEtatTransaction = [
  { text: "Payé", value: "paye" },
  { text: "En attente", value: "attente" },
  { text: "Rejeté", value: "rejete" },
];
export const listTransporter = [
  { id: "classic", name: "DPD Classic" },
  { id: "predict", name: "DPD Predict" },
  { id: "relais", name: "DPD Relais Pickup" },
  { id: "partage", name: "Envoi Partagé" },
  { id: "colissimo", name: "Colissimo" },
  { id: "manuel", name: "Manuel" },
];
export const listDelai = [
  { text: "0 jours", value: "0" },
  { text: "30 jours", value: "30" },
  { text: "30 jours fin de mois", value: "30FM" },
  { text: "45 jours", value: "45" },
  { text: "45 jours fin de mois", value: "45FM" },
  { text: "60 jours", value: "60" },
  { text: "60 jours fin de mois", value: "60FM" },
];
export const listPaiement = [
  { text: "Virement", value: "virement" },
  { text: "Espèce", value: "espece" },
  { text: "Chèque", value: "cheque" },
  { text: "SEPA", value: "sepa" },
  { text: "PayLine", value: "payline" },
  { text: "Mandat Adm", value: "mandat_adm" },
];
export const listDestinationBanque = [
  { text: "Banque Fiducial", value: "fiducial" },
  { text: "Crédit Mutuel", value: "creditMutuel" },
  { text: "Caisse", value: "caisse" },
];
export const listPrivilege = [
  { text: "Administrateur", value: "administrateur" },
  { text: "Commercial", value: "commercial" },
  { text: "Cartographe", value: "cartographe" },
  { text: "Logisticien", value: "logisticien" },
  { text: "Secrétariat", value: "secretariat" },
  { text: "Validateur des paiements", value: "validationPaiement" },
  { text: "Validateur des commandes", value: "validationCommande" },
  { text: "Responsable commercial", value: "responsableCommercial" },
];
export const listReduction = [
  { text: "Remise", value: "remise", disabled: false, used: false },
  { text: "Rabais", value: "rabais", disabled: false, used: false },
  { text: "Ristourne", value: "ristourne", disabled: false, used: false },
  { text: "Escompte", value: "escompte", disabled: false, used: false },
];
export const listFrais = [
  { text: "Port", value: "port", disabled: false, used: false },
  { text: "Emballage", value: "emballage", disabled: false, used: false },
];
export const listDepartement = [
  { value: "01", text: "AIN" },
  { value: "02", text: "AISNE" },
  { value: "03", text: "ALLIER" },
  { value: "04", text: "ALPES-DE-HAUTE-PROVENCE" },
  { value: "05", text: "HAUTES-ALPES" },
  { value: "06", text: "ALPES-MARITIMES" },
  { value: "07", text: "ARDECHE" },
  { value: "08", text: "ARDENNES" },
  { value: "09", text: "ARIEGE" },
  { value: "10", text: "AUBE" },
  { value: "11", text: "AUDE" },
  { value: "12", text: "AVEYRON" },
  { value: "13", text: "BOUCHES-DU-RHONE" },
  { value: "14", text: "CALVADOS" },
  { value: "15", text: "CANTAL" },
  { value: "16", text: "CHARENTE" },
  { value: "17", text: "CHARENTE-MARITIME" },
  { value: "18", text: "CHER" },
  { value: "19", text: "CORREZE" },
  { value: "20", text: "CORSE" },
  { value: "21", text: "COTE-D'OR" },
  { value: "22", text: "COTES-D'ARMOR" },
  { value: "23", text: "CREUSE" },
  { value: "24", text: "DORDOGNE" },
  { value: "25", text: "DOUBS" },
  { value: "26", text: "DROME" },
  { value: "27", text: "EURE" },
  { value: "28", text: "EURE-ET-LOIR" },
  { value: "29", text: "FINISTERE" },
  { value: "30", text: "GARD" },
  { value: "31", text: "HAUTE-GARONNE" },
  { value: "32", text: "GERS" },
  { value: "33", text: "GIRONDE" },
  { value: "34", text: "HERAULT" },
  { value: "35", text: "ILLE-ET-VILAINE" },
  { value: "36", text: "INDRE" },
  { value: "37", text: "INDRE-ET-LOIRE" },
  { value: "38", text: "ISERE" },
  { value: "39", text: "JURA" },
  { value: "40", text: "LANDES" },
  { value: "41", text: "LOIR-ET-CHER" },
  { value: "42", text: "LOIRE" },
  { value: "43", text: "HAUTE-LOIRE" },
  { value: "44", text: "LOIRE-ATLANTIQUE" },
  { value: "45", text: "LOIRET" },
  { value: "46", text: "LOT" },
  { value: "47", text: "LOT-ET-GARONNE" },
  { value: "48", text: "LOZERE" },
  { value: "49", text: "MAINE-ET-LOIRE" },
  { value: "50", text: "MANCHE" },
  { value: "51", text: "MARNE" },
  { value: "52", text: "HAUTE-MARNE" },
  { value: "53", text: "MAYENNE" },
  { value: "54", text: "MEURTHE-ET-MOSELLE" },
  { value: "55", text: "MEUSE" },
  { value: "56", text: "MORBIHAN" },
  { value: "57", text: "MOSELLE" },
  { value: "58", text: "NIEVRE" },
  { value: "59", text: "NORD" },
  { value: "60", text: "OISE" },
  { value: "61", text: "ORNE" },
  { value: "62", text: "PAS-DE-CALAIS" },
  { value: "63", text: "PUY-DE-DOME" },
  { value: "64", text: "PYRENEES-ATLANTIQUES" },
  { value: "65", text: "HAUTES-PYRENEES" },
  { value: "66", text: "PYRENEES-ORIENTALES" },
  { value: "67", text: "BAS-RHIN" },
  { value: "68", text: "HAUT-RHIN" },
  { value: "69", text: "RHONE" },
  { value: "70", text: "HAUTE-SAONE" },
  { value: "71", text: "SAONE-ET-LOIRE" },
  { value: "72", text: "SARTHE" },
  { value: "73", text: "SAVOIE" },
  { value: "74", text: "HAUTE-SAVOIE" },
  { value: "75", text: "PARIS" },
  { value: "76", text: "SEINE-MARITIME" },
  { value: "77", text: "SEINE-ET-MARNE" },
  { value: "78", text: "YVELINES" },
  { value: "79", text: "DEUX-SEVRES" },
  { value: "80", text: "SOMME" },
  { value: "81", text: "TARN" },
  { value: "82", text: "TARN-ET-GARONNE" },
  { value: "83", text: "VAR" },
  { value: "84", text: "VAUCLUSE" },
  { value: "85", text: "VENDEE" },
  { value: "86", text: "VIENNE" },
  { value: "87", text: "HAUTE-VIENNE" },
  { value: "88", text: "VOSGES" },
  { value: "89", text: "YONNE" },
  { value: "90", text: "TERRITOIRE DE BELFORT" },
  { value: "91", text: "ESSONNE" },
  { value: "92", text: "HAUTS-DE-SEINE" },
  { value: "93", text: "SEINE-SAINT-DENIS" },
  { value: "94", text: "VAL-DE-MARNE" },
  { value: "95", text: "VAL-D'OISE" },
  { value: "971", text: "GUADELOUPE" },
  { value: "972", text: "MARTINIQUE" },
  { value: "973", text: "GUYANE" },
  { value: "974", text: "LA REUNION" },
  { value: "976", text: "MAYOTTE" },
];
export const listPays = [
  { code: 4, value: "af", alpha3: "afg", text: "Afghanistan" },
  { code: 710, value: "za", alpha3: "zaf", text: "Afrique du Sud" },
  { code: 248, value: "ax", alpha3: "ala", text: "Îles Åland" },
  { code: 8, value: "al", alpha3: "alb", text: "Albanie" },
  { code: 12, value: "dz", alpha3: "dza", text: "Algérie" },
  { code: 276, value: "de", alpha3: "deu", text: "Allemagne" },
  { code: 20, value: "ad", alpha3: "and", text: "Andorre" },
  { code: 24, value: "ao", alpha3: "ago", text: "Angola" },
  { code: 660, value: "ai", alpha3: "aia", text: "Anguilla" },
  { code: 10, value: "aq", alpha3: "ata", text: "Antarctique" },
  { code: 28, value: "ag", alpha3: "atg", text: "Antigua-et-Barbuda" },
  { code: 682, value: "sa", alpha3: "sau", text: "Arabie saoudite" },
  { code: 32, value: "ar", alpha3: "arg", text: "Argentine" },
  { code: 51, value: "am", alpha3: "arm", text: "Arménie" },
  { code: 533, value: "aw", alpha3: "abw", text: "Aruba" },
  { code: 36, value: "au", alpha3: "aus", text: "Australie" },
  { code: 40, value: "at", alpha3: "aut", text: "Autriche" },
  { code: 31, value: "az", alpha3: "aze", text: "Azerbaïdjan" },
  { code: 44, value: "bs", alpha3: "bhs", text: "Bahamas" },
  { code: 48, value: "bh", alpha3: "bhr", text: "Bahreïn" },
  { code: 50, value: "bd", alpha3: "bgd", text: "Bangladesh" },
  { code: 52, value: "bb", alpha3: "brb", text: "Barbade" },
  { code: 112, value: "by", alpha3: "blr", text: "Biélorussie" },
  { code: 56, value: "be", alpha3: "bel", text: "Belgique" },
  { code: 84, value: "bz", alpha3: "blz", text: "Belize" },
  { code: 204, value: "bj", alpha3: "ben", text: "Bénin" },
  { code: 60, value: "bm", alpha3: "bmu", text: "Bermudes" },
  { code: 64, value: "bt", alpha3: "btn", text: "Bhoutan" },
  { code: 68, value: "bo", alpha3: "bol", text: "Bolivie" },
  { code: 535, value: "bq", alpha3: "bes", text: "Pays-Bas caribéens" },
  { code: 70, value: "ba", alpha3: "bih", text: "Bosnie-Herzégovine" },
  { code: 72, value: "bw", alpha3: "bwa", text: "Botswana" },
  { code: 74, value: "bv", alpha3: "bvt", text: "Île Bouvet" },
  { code: 76, value: "br", alpha3: "bra", text: "Brésil" },
  { code: 96, value: "bn", alpha3: "brn", text: "Brunei" },
  { code: 100, value: "bg", alpha3: "bgr", text: "Bulgarie" },
  { code: 854, value: "bf", alpha3: "bfa", text: "Burkina Faso" },
  { code: 108, value: "bi", alpha3: "bdi", text: "Burundi" },
  { code: 136, value: "ky", alpha3: "cym", text: "Îles Caïmans" },
  { code: 116, value: "kh", alpha3: "khm", text: "Cambodge" },
  { code: 120, value: "cm", alpha3: "cmr", text: "Cameroun" },
  { code: 124, value: "ca", alpha3: "can", text: "Canada" },
  { code: 132, value: "cv", alpha3: "cpv", text: "Cap-Vert" },
  { code: 140, value: "cf", alpha3: "caf", text: "République centrafricaine" },
  { code: 152, value: "cl", alpha3: "chl", text: "Chili" },
  { code: 156, value: "cn", alpha3: "chn", text: "Chine" },
  { code: 162, value: "cx", alpha3: "cxr", text: "Île Christmas" },
  { code: 196, value: "cy", alpha3: "cyp", text: "Chypre" },
  { code: 166, value: "cc", alpha3: "cck", text: "Îles Cocos" },
  { code: 170, value: "co", alpha3: "col", text: "Colombie" },
  { code: 174, value: "km", alpha3: "com", text: "Comores" },
  { code: 178, value: "cg", alpha3: "cog", text: "République du Congo" },
  { code: 180, value: "cd", alpha3: "cod", text: "République démocratique du Congo" },
  { code: 184, value: "ck", alpha3: "cok", text: "Îles Cook" },
  { code: 410, value: "kr", alpha3: "kor", text: "Corée du Sud" },
  { code: 408, value: "kp", alpha3: "prk", text: "Corée du Nord" },
  { code: 188, value: "cr", alpha3: "cri", text: "Costa Rica" },
  { code: 384, value: "ci", alpha3: "civ", text: "Côte d'Ivoire" },
  { code: 191, value: "hr", alpha3: "hrv", text: "Croatie" },
  { code: 192, value: "cu", alpha3: "cub", text: "Cuba" },
  { code: 531, value: "cw", alpha3: "cuw", text: "Curaçao" },
  { code: 208, value: "dk", alpha3: "dnk", text: "Danemark" },
  { code: 262, value: "dj", alpha3: "dji", text: "Djibouti" },
  { code: 214, value: "do", alpha3: "dom", text: "République dominicaine" },
  { code: 212, value: "dm", alpha3: "dma", text: "Dominique" },
  { code: 818, value: "eg", alpha3: "egy", text: "Égypte" },
  { code: 222, value: "sv", alpha3: "slv", text: "Salvador" },
  { code: 784, value: "ae", alpha3: "are", text: "Émirats arabes unis" },
  { code: 218, value: "ec", alpha3: "ecu", text: "Équateur" },
  { code: 232, value: "er", alpha3: "eri", text: "Érythrée" },
  { code: 724, value: "es", alpha3: "esp", text: "Espagne" },
  { code: 233, value: "ee", alpha3: "est", text: "Estonie" },
  { code: 840, value: "us", alpha3: "usa", text: "États-Unis" },
  { code: 231, value: "et", alpha3: "eth", text: "Éthiopie" },
  { code: 238, value: "fk", alpha3: "flk", text: "Malouines" },
  { code: 234, value: "fo", alpha3: "fro", text: "Îles Féroé" },
  { code: 242, value: "fj", alpha3: "fji", text: "Fidji" },
  { code: 246, value: "fi", alpha3: "fin", text: "Finlande" },
  { code: 250, value: "fr", alpha3: "fra", text: "France" },
  { code: 266, value: "ga", alpha3: "gab", text: "Gabon" },
  { code: 270, value: "gm", alpha3: "gmb", text: "Gambie" },
  { code: 268, value: "ge", alpha3: "geo", text: "Géorgie" },
  { code: 239, value: "gs", alpha3: "sgs", text: "Géorgie du Sud-et-les îles Sandwich du Sud" },
  { code: 288, value: "gh", alpha3: "gha", text: "Ghana" },
  { code: 292, value: "gi", alpha3: "gib", text: "Gibraltar" },
  { code: 300, value: "gr", alpha3: "grc", text: "Grèce" },
  { code: 308, value: "gd", alpha3: "grd", text: "Grenade" },
  { code: 304, value: "gl", alpha3: "grl", text: "Groenland" },
  { code: 312, value: "gp", alpha3: "glp", text: "Guadeloupe" },
  { code: 316, value: "gu", alpha3: "gum", text: "Guam" },
  { code: 320, value: "gt", alpha3: "gtm", text: "Guatemala" },
  { code: 831, value: "gg", alpha3: "ggy", text: "Guernesey" },
  { code: 324, value: "gn", alpha3: "gin", text: "Guinée" },
  { code: 624, value: "gw", alpha3: "gnb", text: "Guinée-Bissau" },
  { code: 226, value: "gq", alpha3: "gnq", text: "Guinée équatoriale" },
  { code: 328, value: "gy", alpha3: "guy", text: "Guyana" },
  { code: 254, value: "gf", alpha3: "guf", text: "Guyane" },
  { code: 332, value: "ht", alpha3: "hti", text: "Haïti" },
  { code: 334, value: "hm", alpha3: "hmd", text: "Îles Heard-et-MacDonald" },
  { code: 340, value: "hn", alpha3: "hnd", text: "Honduras" },
  { code: 344, value: "hk", alpha3: "hkg", text: "Hong Kong" },
  { code: 348, value: "hu", alpha3: "hun", text: "Hongrie" },
  { code: 833, value: "im", alpha3: "imn", text: "Île de Man" },
  { code: 581, value: "um", alpha3: "umi", text: "Îles mineures éloignées des États-Unis" },
  { code: 92, value: "vg", alpha3: "vgb", text: "Îles Vierges britanniques" },
  { code: 850, value: "vi", alpha3: "vir", text: "Îles Vierges des États-Unis" },
  { code: 356, value: "in", alpha3: "ind", text: "Inde" },
  { code: 360, value: "code", alpha3: "idn", text: "Indonésie" },
  { code: 364, value: "ir", alpha3: "irn", text: "Iran" },
  { code: 368, value: "iq", alpha3: "irq", text: "Irak" },
  { code: 372, value: "ie", alpha3: "irl", text: "Irlande" },
  { code: 352, value: "is", alpha3: "isl", text: "Islande" },
  { code: 376, value: "il", alpha3: "isr", text: "Israël" },
  { code: 380, value: "it", alpha3: "ita", text: "Italie" },
  { code: 388, value: "jm", alpha3: "jam", text: "Jamaïque" },
  { code: 392, value: "jp", alpha3: "jpn", text: "Japon" },
  { code: 832, value: "je", alpha3: "jey", text: "Jersey" },
  { code: 400, value: "jo", alpha3: "jor", text: "Jordanie" },
  { code: 398, value: "kz", alpha3: "kaz", text: "Kazakhstan" },
  { code: 404, value: "ke", alpha3: "ken", text: "Kenya" },
  { code: 417, value: "kg", alpha3: "kgz", text: "Kirghizistan" },
  { code: 296, value: "ki", alpha3: "kir", text: "Kiribati" },
  { code: 414, value: "kw", alpha3: "kwt", text: "Koweït" },
  { code: 418, value: "la", alpha3: "lao", text: "Laos" },
  { code: 426, value: "ls", alpha3: "lso", text: "Lesotho" },
  { code: 428, value: "lv", alpha3: "lva", text: "Lettonie" },
  { code: 422, value: "lb", alpha3: "lbn", text: "Liban" },
  { code: 430, value: "lr", alpha3: "lbr", text: "Liberia" },
  { code: 434, value: "ly", alpha3: "lby", text: "Libye" },
  { code: 438, value: "li", alpha3: "lie", text: "Liechtenstein" },
  { code: 440, value: "lt", alpha3: "ltu", text: "Lituanie" },
  { code: 442, value: "lu", alpha3: "lux", text: "Luxembourg" },
  { code: 446, value: "mo", alpha3: "mac", text: "Macao" },
  { code: 807, value: "mk", alpha3: "mkd", text: "Macédoine du Nord" },
  { code: 450, value: "mg", alpha3: "mdg", text: "Madagascar" },
  { code: 458, value: "my", alpha3: "mys", text: "Malaisie" },
  { code: 454, value: "mw", alpha3: "mwi", text: "Malawi" },
  { code: 462, value: "mv", alpha3: "mdv", text: "Maldives" },
  { code: 466, value: "ml", alpha3: "mli", text: "Mali" },
  { code: 470, value: "mt", alpha3: "mlt", text: "Malte" },
  { code: 580, value: "mp", alpha3: "mnp", text: "Îles Mariannes du Nord" },
  { code: 504, value: "ma", alpha3: "mar", text: "Maroc" },
  { code: 584, value: "mh", alpha3: "mhl", text: "Îles Marshall" },
  { code: 474, value: "mq", alpha3: "mtq", text: "Martinique" },
  { code: 480, value: "mu", alpha3: "mus", text: "Maurice" },
  { code: 478, value: "mr", alpha3: "mrt", text: "Mauritanie" },
  { code: 175, value: "yt", alpha3: "myt", text: "Mayotte" },
  { code: 484, value: "mx", alpha3: "mex", text: "Mexique" },
  { code: 583, value: "fm", alpha3: "fsm", text: "États fédérés de Micronésie" },
  { code: 498, value: "md", alpha3: "mda", text: "Moldavie" },
  { code: 492, value: "mc", alpha3: "mco", text: "Monaco" },
  { code: 496, value: "mn", alpha3: "mng", text: "Mongolie" },
  { code: 499, value: "me", alpha3: "mne", text: "Monténégro" },
  { code: 500, value: "ms", alpha3: "msr", text: "Montserrat" },
  { code: 508, value: "mz", alpha3: "moz", text: "Mozambique" },
  { code: 104, value: "mm", alpha3: "mmr", text: "Birmanie" },
  { code: 516, value: "na", alpha3: "nam", text: "Namibie" },
  { code: 520, value: "nr", alpha3: "nru", text: "Nauru" },
  { code: 524, value: "np", alpha3: "npl", text: "Népal" },
  { code: 558, value: "ni", alpha3: "nic", text: "Nicaragua" },
  { code: 562, value: "ne", alpha3: "ner", text: "Niger" },
  { code: 566, value: "ng", alpha3: "nga", text: "Nigeria" },
  { code: 570, value: "nu", alpha3: "niu", text: "Niue" },
  { code: 574, value: "nf", alpha3: "nfk", text: "Île Norfolk" },
  { code: 578, value: "no", alpha3: "nor", text: "Norvège" },
  { code: 540, value: "nc", alpha3: "ncl", text: "Nouvelle-Calédonie" },
  { code: 554, value: "nz", alpha3: "nzl", text: "Nouvelle-Zélande" },
  { code: 86, value: "io", alpha3: "iot", text: "Territoire britannique de l'océan Indien" },
  { code: 512, value: "om", alpha3: "omn", text: "Oman" },
  { code: 800, value: "ug", alpha3: "uga", text: "Ouganda" },
  { code: 860, value: "uz", alpha3: "uzb", text: "Ouzbékistan" },
  { code: 586, value: "pk", alpha3: "pak", text: "Pakistan" },
  { code: 585, value: "pw", alpha3: "plw", text: "Palaos" },
  { code: 275, value: "ps", alpha3: "pse", text: "Palestine" },
  { code: 591, value: "pa", alpha3: "pan", text: "Panama" },
  { code: 598, value: "pg", alpha3: "png", text: "Papouasie-Nouvelle-Guinée" },
  { code: 600, value: "py", alpha3: "pry", text: "Paraguay" },
  { code: 528, value: "nl", alpha3: "nld", text: "Pays-Bas" },
  { code: 604, value: "pe", alpha3: "per", text: "Pérou" },
  { code: 608, value: "ph", alpha3: "phl", text: "Philippines" },
  { code: 612, value: "pn", alpha3: "pcn", text: "Îles Pitcairn" },
  { code: 616, value: "pl", alpha3: "pol", text: "Pologne" },
  { code: 258, value: "pf", alpha3: "pyf", text: "Polynésie française" },
  { code: 630, value: "pr", alpha3: "pri", text: "Porto Rico" },
  { code: 620, value: "pt", alpha3: "prt", text: "Portugal" },
  { code: 634, value: "qa", alpha3: "qat", text: "Qatar" },
  { code: 638, value: "re", alpha3: "reu", text: "La Réunion" },
  { code: 642, value: "ro", alpha3: "rou", text: "Roumanie" },
  { code: 826, value: "gb", alpha3: "gbr", text: "Royaume-Uni" },
  { code: 643, value: "ru", alpha3: "rus", text: "Russie" },
  { code: 646, value: "rw", alpha3: "rwa", text: "Rwanda" },
  { code: 732, value: "eh", alpha3: "esh", text: "République arabe sahraouie démocratique" },
  { code: 652, value: "bl", alpha3: "blm", text: "Saint-Barthélemy" },
  { code: 659, value: "kn", alpha3: "kna", text: "Saint-Christophe-et-Niévès" },
  { code: 674, value: "sm", alpha3: "smr", text: "Saint-Marin" },
  { code: 663, value: "mf", alpha3: "maf", text: "Saint-Martin" },
  { code: 534, value: "sx", alpha3: "sxm", text: "Saint-Martin" },
  { code: 666, value: "pm", alpha3: "spm", text: "Saint-Pierre-et-Miquelon" },
  { code: 336, value: "va", alpha3: "vat", text: "Saint-Siège (État de la Cité du Vatican)" },
  { code: 670, value: "vc", alpha3: "vct", text: "Saint-Vincent-et-les-Grenadines" },
  { code: 654, value: "sh", alpha3: "shn", text: "Sainte-Hélène, Ascension et Tristan da Cunha" },
  { code: 662, value: "lc", alpha3: "lca", text: "Sainte-Lucie" },
  { code: 90, value: "sb", alpha3: "slb", text: "Îles Salomon" },
  { code: 882, value: "ws", alpha3: "wsm", text: "Samoa" },
  { code: 16, value: "as", alpha3: "asm", text: "Samoa américaines" },
  { code: 678, value: "st", alpha3: "stp", text: "Sao Tomé-et-Principe" },
  { code: 686, value: "sn", alpha3: "sen", text: "Sénégal" },
  { code: 688, value: "rs", alpha3: "srb", text: "Serbie" },
  { code: 690, value: "sc", alpha3: "syc", text: "Seychelles" },
  { code: 694, value: "sl", alpha3: "sle", text: "Sierra Leone" },
  { code: 702, value: "sg", alpha3: "sgp", text: "Singapour" },
  { code: 703, value: "sk", alpha3: "svk", text: "Slovaquie" },
  { code: 705, value: "si", alpha3: "svn", text: "Slovénie" },
  { code: 706, value: "so", alpha3: "som", text: "Somalie" },
  { code: 729, value: "sd", alpha3: "sdn", text: "Soudan" },
  { code: 728, value: "ss", alpha3: "ssd", text: "Soudan du Sud" },
  { code: 144, value: "lk", alpha3: "lka", text: "Sri Lanka" },
  { code: 752, value: "se", alpha3: "swe", text: "Suède" },
  { code: 756, value: "ch", alpha3: "che", text: "Suisse" },
  { code: 740, value: "sr", alpha3: "sur", text: "Suriname" },
  { code: 744, value: "sj", alpha3: "sjm", text: "Svalbard et ile Jan Mayen" },
  { code: 748, value: "sz", alpha3: "swz", text: "Eswatini" },
  { code: 760, value: "sy", alpha3: "syr", text: "Syrie" },
  { code: 762, value: "tj", alpha3: "tjk", text: "Tadjikistan" },
  { code: 158, value: "tw", alpha3: "twn", text: "Taïwan / (République de Chine (Taïwan))" },
  { code: 834, value: "tz", alpha3: "tza", text: "Tanzanie" },
  { code: 148, value: "td", alpha3: "tcd", text: "Tchad" },
  { code: 203, value: "cz", alpha3: "cze", text: "Tchéquie" },
  { code: 260, value: "tf", alpha3: "atf", text: "Terres australes et antarctiques françaises" },
  { code: 764, value: "th", alpha3: "tha", text: "Thaïlande" },
  { code: 626, value: "tl", alpha3: "tls", text: "Timor oriental" },
  { code: 768, value: "tg", alpha3: "tgo", text: "Togo" },
  { code: 772, value: "tk", alpha3: "tkl", text: "Tokelau" },
  { code: 776, value: "to", alpha3: "ton", text: "Tonga" },
  { code: 780, value: "tt", alpha3: "tto", text: "Trinité-et-Tobago" },
  { code: 788, value: "tn", alpha3: "tun", text: "Tunisie" },
  { code: 795, value: "tm", alpha3: "tkm", text: "Turkménistan" },
  { code: 796, value: "tc", alpha3: "tca", text: "Îles Turques-et-Caïques" },
  { code: 792, value: "tr", alpha3: "tur", text: "Turquie" },
  { code: 798, value: "tv", alpha3: "tuv", text: "Tuvalu" },
  { code: 804, value: "ua", alpha3: "ukr", text: "Ukraine" },
  { code: 858, value: "uy", alpha3: "ury", text: "Uruguay" },
  { code: 548, value: "vu", alpha3: "vut", text: "Vanuatu" },
  { code: 862, value: "ve", alpha3: "ven", text: "Venezuela" },
  { code: 704, value: "vn", alpha3: "vnm", text: "Viêt Nam" },
  { code: 876, value: "wf", alpha3: "wlf", text: "Wallis-et-Futuna" },
  { code: 887, value: "ye", alpha3: "yem", text: "Yémen" },
  { code: 894, value: "zm", alpha3: "zmb", text: "Zambie" },
  { code: 716, value: "zw", alpha3: "zwe", text: "Zimbabwe" },
];

export const listTVA = [
  { value: 20, compte: 445718 },
  { value: 5.5, compte: 445715 },
  { value: 2.1, compte: 44571 },
  { value: 0, compte: 44571 },
];

export const listTVAFrais = [
  { value: 20, compte: 445718 },
  { value: 0, compte: 44571 },
];

export const listGoodAccount = [
  { VAT: 20, VATAccount: 445718, account: 707100 },
  { VAT: 5.5, VATAccount: 445715, account: 707155 },
  { VAT: 2.1, VATAccount: 445711, account: 707121 },
  { VAT: 0, VATAccount: 0, account: 707200 },
];

export const listFeeAccount = [
  { VAT: 20, VATAccount: 445718, account: 708500 },
  { VAT: 5.5, VATAccount: 445715, account: 708550 },
  { VAT: 2.1, VATAccount: 445711, account: 708521 },
  { VAT: 0, VATAccount: 0, account: 707200 },
];

export const qrCodeTargetTypeList = [
  { id: QrCodeTargetType.URL, text: "URL" },
  { id: QrCodeTargetType.PRODUCT_PAGE, text: "Produit" },
  { id: QrCodeTargetType.PRODUCT_LEGEND, text: "Légende" },
];
