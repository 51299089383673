import controller from '@/init'

export default new class {
  appVersion = __APP_VERSION__ + "/" + __ENV__
  constructor () {
    this.login = ''
    this.password = ''
  }

  connect = () => controller.connect(this.login, this.password)
}()
