import { Component, ref } from "vue";

const modalComponent = ref();
const show = ref(false);
const modalProps = ref({});
const modalOn = ref({});

export const useModal = (component: Component, props: Record<string, unknown>, on = {}) => {
  const open = () => {
    modalProps.value = props;
    modalOn.value = on;
    modalComponent.value = component;
    show.value = true;
  };

  const close = () => {
    show.value = false;
    modalComponent.value = null;
    modalProps.value = {};
    modalOn.value = {};
  };

  return { open, close };
};

export const _useModalManager = () => {
  return { component: modalComponent, show, props: modalProps, on: modalOn };
};
