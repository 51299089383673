import "reflect-metadata"
import Vue from "vue"; // Obligatoire
import App from "./App.vue"; // keep
import router from "./router"; // keep
import vuetify from "./plugins/vuetify"; // keep
import Vue2Editor from "vue2-editor"; // keep
import "./plugins/vuetify-money.js"; // parsing pour afficher les prix
import { createPinia, PiniaVuePlugin } from 'pinia'
import "animate.css"


import AdminOnly from "@/apps/components/permission/AdminOnly.vue";

import "@/_sass/style.scss";
import { formatterMixin } from "./mixins/formatterMixin";
import { permissionMixin } from "./mixins/permissionMixin";
import { utilsMixin } from "./mixins/utilsMixin";

Vue.component("AdminOnly", AdminOnly);
Vue.use(PiniaVuePlugin)
Vue.use(Vue2Editor);

Vue.config.productionTip = false;

Vue.mixin(formatterMixin);
Vue.mixin(permissionMixin);
Vue.mixin(utilsMixin);

const pinia = createPinia()
window.vm = new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  pinia
}).$mount("#app");