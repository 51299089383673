export const moneyFormatter = new Intl.NumberFormat("fr-FR", {
  currency: "EUR",
  style: "currency"
});

export const dateFormatter = new Intl.DateTimeFormat("fr-FR")
export const datetimeFormatter = new Intl.DateTimeFormat("fr-FR", {
  hour: "2-digit",
  minute: "2-digit",
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
})

export const weightFormatter = new Intl.NumberFormat("fr-Fr", {
  style: "unit",
  unit: "gram"
})

export const monthFormatter = new Intl.DateTimeFormat("fr", {
  month: "2-digit",
  year: "numeric"
})

export const booleanFormatter = (bool) => {
  return bool ? "Oui" : "Non"
}

export const percentFormatter = new Intl.NumberFormat("fr-Fr", {
  style: "percent",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
