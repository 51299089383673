export enum QrCodeTargetType {
    URL = "url",
    PRODUCT_PAGE = "product_page",
    PRODUCT_LEGEND = "product_legend",
  }
  
  export type QrCode = { _id: string, slug: string; disabled: boolean; scans: number } & (
    | { target: { type: QrCodeTargetType.URL; value: string } }
    | { target: { type: QrCodeTargetType.PRODUCT_PAGE | QrCodeTargetType.PRODUCT_LEGEND; value: number } }
  );
  
  