import { listCommandShippingStatut, listCommandStatut, listFluxStatut, listInvoiceStatut, listPaiement, listTransporter, qrCodeTargetTypeList } from "@/shared";

export const mapFluxStatut = statutMapper(listFluxStatut);
export const mapTransporterName = statutMapper(listTransporter.map(item => ({ id: item.id, text: item.name })));
export const mapCommandShippingStatut = statutMapper(listCommandShippingStatut);
export const mapInvoiceStatut = statutMapper(listInvoiceStatut);
export const mapCommandStatut = statutMapper(listCommandStatut);
export const mapPaymentMethod = statutMapper(listPaiement.map(item => ({ id: item.value, text: item.text })));
export const mapQrCodeTargetType = statutMapper(qrCodeTargetTypeList)

export function statutMapper(collection) {
  return (statut) => (collection.find((item) => item.id === statut) ?? { text: statut }).text;
}

export function convert_ht_to_ttc(ht = 0, taux = 0) {
  return ht + convert_ht_to_tva(ht, taux)
}

export function convert_ht_to_tva(ht = 0, taux = 0) {
  return ht * (taux / 100)
}

export function convert_ttc_to_tva(ttc = 0, taux = 0) {
  return (ttc - ttc / (1 + taux / 100));
}

export function convert_ttc_to_ht(ttc = 0, taux = 0) {
  return ttc - convert_ttc_to_tva(ttc, taux)
}

export function trim_price(price) {
  return Math.round(price * 100) / 100
}

/**
 * 
 * @param {number} a 
 * @param {number} b 
 * @returns {boolean}
 */
export function compareVAT(a, b) {
  return Math.abs(trim_price(a) - trim_price(b)) < Number.EPSILON
}

export const utilsMixin = {
  methods: {
    mapFluxStatut,
    mapTransporterName,
    mapCommandShippingStatut,
    mapInvoiceStatut,
    mapCommandStatut,
    mapPaymentMethod,
    convert_ht_to_ttc,
    convert_ht_to_tva,
    convert_ttc_to_tva,
    convert_ttc_to_ht,
  },
};
